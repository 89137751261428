<template>
  <AppInputDropdown
    v-model="model"
    :label="label"
    class="countries"
    :options="countries"
    :border="border"
    :disabled="$attrs.disabled"
    selected-display-field="text"
    selected-value-field="value"
    @input="selectCountry($event)"
  >
    <template #selected="propSelected">
      <div class="country-item">
        <span v-if="preText" class="country-pre-text">{{ preText }}</span>
        <span v-if="!countryNameAfterIcon" class="country-name-before-icon">{{
          getCountryText(getCountry(propSelected.value))
        }}</span>
        <AppFlag class="country-flag">
          <Component :is="getFlag(getCountry(propSelected.value))"></Component>
        </AppFlag>
        <span v-if="countryNameAfterIcon" class="country-name-after-icon">{{
          getCountryText(getCountry(propSelected.value))
        }}</span>
      </div>
    </template>
    <template #option="{ option }">
      <div class="country-item">
        <AppFlag class="country-flag">
          <Component :is="getFlag(option)"></Component>
        </AppFlag>
        <span class="country-name-after-icon">{{ option.text }}</span>
      </div>
    </template>
  </AppInputDropdown>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useComponentId } from '@/composables/useComponent'
import { useValidationHelpers } from '@/composables/useValidationHelpers'
import AppInputDropdown from '@/components/AppInputDropdown/AppInputDropdown'
import AppFlag from '@/components/AppFlag/AppFlag'
import * as EuronetFlags from '@moneytransfer.ui/euronet-flags'

export default {
  name: 'AppInputCountry',
  emits: ['input'],
  components: {
    AppInputDropdown,
    AppFlag,
  },
  props: {
    value: {
      type: String,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    countries: {
      type: Array,
      required: true,
      default: () => [],
    },
    validation: {
      type: Object,
      default: () => {},
    },
    preText: {
      type: String,
      default: '',
    },
    border: {
      type: Boolean,
      default: true,
    },
    countryNameAfterIcon: {
      type: Boolean,
      default: true,
    },
    onSelect: {
      type: Function,
      default: null,
    },
  },
  setup(props, { emit }) {
    const model = computed({
      get: () => props.value,
      set: (val) => emit('input', val),
    })

    const id = useComponentId()
    const validationHelpers = useValidationHelpers(props.validation)

    const getFlag = (option) => {
      let flag = null
      if (option) {
        if (option.flag) {
          flag = 'Flag' + option.flag.toUpperCase()
        } else if (option.value) {
          flag = 'Flag' + option.value.toUpperCase()
        }
      }
      if (flag === 'FlagKV') {
        flag = 'FlagCV'
      }
      return EuronetFlags[flag] || null
    }

    const getCountry = (value) => {
      return props.countries.find((country) => country.value === value)
    }

    const getCountryText = (option) => {
      if (option) {
        return option.text
      }
      return ''
    }

    const selectCountry = (country) => {
      emit('input', country)
      if (props.onSelect) {
        props.onSelect(country)
      }
    }

    return {
      model,
      id,
      validationHelpers,
      getFlag,
      getCountry,
      getCountryText,
      selectCountry,
    }
  },
}
</script>

<style lang="postcss" scoped>
.country-item {
  @apply flex flex-row items-center;
}

.country-pre-text {
  @apply mr-1;
}

.country-name-before-icon {
  @apply mr-2;
  @apply font-bold;
}

.country-name-after-icon {
  @apply ml-2;
}
</style>
